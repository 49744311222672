require("./bootstrap");

import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import translate from "./translate";

// if (process.env.NODE_ENV == "production") {
//     const analytics = Analytics({
//         app: "5forummw",
//         plugins: [
//             googleAnalytics({
//                 measurementIds: ["G-B2XCD3R8N9"],
//             }),
//         ],
//     });
//     /* Track a page view */
//     analytics.page();
// }

function checkPhoneField() {
    const country = COUNTRIES.find(
        (el) =>
            el?.name ==
            $("select[name=country]").children("option:selected").val()
    );
    if (country) {
        $("input[name=phone]").val("+" + country?.calling_code);
        $("input[name=mobile_phone]").val("+" + country?.calling_code);
    }
}
function checkWorkshops(e) {
    const totalChecked = $("input[name='workshops[]']").filter(
        ":checked"
    ).length;
    if (totalChecked > 3) {
        $(e.target).prop("checked", false);
    }
}
function calculateDateDifference() {
    const arrivalDate = new Date(
        $("input[name='expected_arrival_date']").val()
    );
    const departureDate = new Date(
        $("input[name='expected_departure_date']").val()
    );

    if (departureDate <= arrivalDate) {
        $("input[name='expected_departure_date']").val("");
        return null;
    } else {
        var timeDiff = departureDate - arrivalDate;
        var dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff;
    }
}
function calculateTotalToPay() {
    let total = $("input[name=fee_type]:checked").data("price") || 0;
    $("#fee-card-alert").hide()
    $("#fee-card-alert").html("")
    // if (
    //     $("input[name='expected_arrival_date']").val() &&
    //     $("input[name='expected_departure_date']").val()
    // ) {
    //     const dayDiff = calculateDateDifference();
    //     const hotelOfferPrice =
    //         $("input[name='hotel_offer']:checked").data("price") || 0;
    //     if (dayDiff == null) {
    //         $("#fee-card-alert").html(
    //             "La date de départ prévue doit être postérieure à la date d'arrivée prévue !"
    //         );
    //         $("#fee-card-alert").show()
    //         $("#total_to_pay").val("");
    //         return;
    //     }
    //     total += hotelOfferPrice * dayDiff;
    // }
    if ($("input[name='extra']:checked").val() == 'yes') {
        let extra = 100;
        if (document.documentElement.lang == 'en')
            extra = 38
        total += (parseInt($("input[name=fee_type]:checked").data("nights")) * extra)
    }
    $("#total_to_pay").val(`${total || 0} ${document.documentElement.lang == 'fr' ? "DT" : "USD"}`);
}

function handleFirmName() {
    const paymentMethod = $("input[name='payment_method']:checked").val();
    if (paymentMethod == 'firm') {
        $("#firm-name-wrapper").show();
        $("label[for='firm_name']").addClass('required');
        $("input[name='firm_name']").attr('required', true)
    } else {
        $("#firm-name-wrapper").hide();
        $("label[for='firm_name']").removeClass('required');
        $("input[name='firm_name']").attr('required', false)
    }
}

$(function () {
    handleFirmName()
    calculateTotalToPay()
    $("select[name=country]").change(function (e) {
        checkPhoneField();
    });
    $("input[name='workshops[]']").click(function (e) {
        checkWorkshops(e);
    });
    $("input[name='hotel_offer']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[type='date']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[name='fee_type']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[name='extra']").change(function (e) {
        calculateTotalToPay();
    });
    $("input[name='payment_method']").change(function (e) {
        handleFirmName();
    });
});
